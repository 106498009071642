import * as React from "react"
import { Container } from "react-bootstrap"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


const IndexPage = () => (
  <Layout>
    <Seo title="Contact" />
    <div className="blueBG">
      <Container className="singleCol">
        <h1>
        Contact Us
        </h1>
       
      </Container>
    </div>
    <Container className="max-width-600 two-rem-padding">
      <p className="text-center py-4">Thank you! We will be in touch shortly.</p>
    
    </Container>
  </Layout>
)

export default IndexPage
